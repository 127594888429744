import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import handleErrors from '@/services/handle-errors';
import createNetwork from '@/classes/createNetwork';
import http, { cancelTokenNames } from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  list: '/api/v1/admin/networks',
  store: '/api/v1/admin/networks',
  update: '/api/v1/admin/networks/{id}',
  sort: '/api/v1/admin/networks/sort',
  sortServiceTypes: '/api/v1/admin/networks/{networkId}/service-types/sort',
  serviceTypesByNetworkId: 'api/v1/admin/networks/{networkId}/service-types',
  services: 'api/v1/admin/service-types/{serviceTypeID}/services',
});

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('list');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.NETWORKS_TABLE,
    });

    return {
      items: response.data.data.map(createNetwork),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  list() {
    const endpoint = getURL('list');

    return http.get(endpoint)
      .then((response) => response.data.data);
  },
  servicesList(serviceTypeID) {
    const endpoint = getURL('services', { serviceTypeID });

    return http.get(endpoint)
      .then((response) => response.data.data);
  },
  store(data) {
    const endpoint = getURL('store');

    return http.post(endpoint, {
      name: data.name,
      description: data.description,
      network: data.network,
    })
      .catch((error) => handleErrors(error));
  },
  sort(networkIds) {
    const endpoint = getURL('sort');

    return http.post(endpoint, {
      networks: networkIds,
    })
      .catch((error) => handleErrors(error));
  },
  sortServiceTypes(networkId, serviceTypeIds) {
    const endpoint = getURL('sortServiceTypes', { networkId });

    return http.post(endpoint, {
      service_types: serviceTypeIds,
    })
      .catch((error) => handleErrors(error));
  },
  serviceTypesByNetworkId(networkId) {
    const endpoint = getURL('serviceTypesByNetworkId', { networkId });

    return http.get(endpoint).then((response) => response.data.data);
  },
  getURL,
};
