import { defaultState } from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  CLEAR_ERROR: (state, value) => { state.errors[value] = []; },
  SET_LOADING: (state, value) => { state.isLoad = value; },
  SET_IS_ARCHIVE: (state, value) => { state.isArchive = value; },
  SET_LOCALE: (state, value) => { state.selectedLocale = value; },
  SET_NETWORK: (state, value) => { state.network = value; },
  SET_SERVICE_TYPE: (state, value) => { state.serviceType = value; },
  SET_ID: (state, value) => { state.id = value; },
  SET_TRANSLATION: (state, value) => { state.translations = { ...value }; },
  SET_PLACE: (state, value) => { state.place = value; },
  SET_IS_ENABLED: (state, value) => { state.isEnabled = value; },
  SET_SERVICES: (state, value) => { state.services.push(...value); },
  SET_SERVICE_TYPES: (state, value) => { state.serviceTypes.push(...value); },
  RESET_SERVICES: (state) => { state.services = []; },
  RESET_SERVICES_SERVICE_TYPES: (state) => { state.serviceTypes = []; },
  SET_PAGE_SERVICES: (state) => { state.pageServices += 1; },
  RESET_PAGE_SERVICES: (state, value) => { state.pageServices = value; },
  SET_CAN_MOREL_LOAD_SERVICES: (state, value) => { state.canMoreLoadServices = value; },
  SET_NETWORKS: (state, value) => { state.networks = value; },
  SET_SELECTED_SERVICES: (state, value) => { state.selectedServices = value; },
  SET_SELECTED_SERVICE_TYPES: (state, value) => { state.selectedServiceTypes = value; },
  SET_PLACES: (state, value) => { state.places = value; },
  SET_POSITIONS: (state, value) => { state.positions = value; },
  SET_POSITION: (state, value) => { state.position = value; },
  SET_ERRORS: (state, value) => { state.errors = value; },
  SET_FILTERS: (state, value) => { state.filters = value; },
  SET_FREE_TRIALS(state, value) { state.freeTrialsList = value; },
  SET_IS_SHOWN(state, value) { state.isShown = value; },
  SET_OFFER_ID(state, value) { state.offerId = value; },
};
