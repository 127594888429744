import toCamelCase from '@/utils/toCamelCase';

export default function createBanners(data) {
  if (!data) {
    return null;
  }

  function transformTranslations(translations) {
    return Object.keys(translations).reduce((acc, langKey) => {
      if (Object.prototype.hasOwnProperty.call(translations, langKey)) {
        acc[langKey] = Object.keys(translations[langKey]).reduce((innerAcc, key) => {
          if (Object.prototype.hasOwnProperty.call(translations[langKey], key)) {
            const camelKey = toCamelCase(key);

            innerAcc[camelKey] = translations[langKey][key];
          }

          return innerAcc;
        }, {});
      }

      return acc;
    }, {});
  }

  return {
    content: data.content || null,
    id: data.id || null,
    informations: data.informations.length ? data.informations?.map((info) => {
      const infoObject = {
        backgroundImage: info.background_image,
        backgroundColor: info.background_color,
        image: info.image,
        sorting: info.sorting,
        translations: transformTranslations(info.translations),
      };

      if (info?.id) {
        infoObject.id = info.id;
      }

      return infoObject;
    }) : [],
    type: data.type,
    place: data.place || null,
    scrollDelay: data.scroll_delay || null,
  };
}
