export default function createInvoiceFromResource(data) {
  return {
    id: data.id,
    isEnabled: data.is_enabled,
    isArchived: data.is_archived,
    name: data.name,
    network: data.network,
    serviceType: data.service_types.map((serviceType) => serviceType.name).join(', '),
    place: data.place,
    status: data.status,
    position: data.position,
    actions: {
      disable: data.actions.disable,
      enable: data.actions.enable,
      archival: data.actions.archival,
      restore: data.actions.restore,
    },
  };
}
