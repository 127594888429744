import settings from '@/settings';
import {
  createDateFilter,
  createTextFilter,
  createPaymentMethodsFilter,
  createTransactionsFilter,
  createTransactionsTypeFilter,
} from '../builder-definition/filters/table';
import {
  createCellLink,
  createCellMoney,
  createCellNumberAndTooltips,
} from '../builder-definition/tables';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'payments.id',
      width: '68px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
    },
    {
      value: 'user',
      name: 'users.email',
      text: 'Users',
      width: '100px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search User' }),
      ...createCellLink({
        textProp: 'user.email',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      value: 'user.telegramUsername',
      text: 'Telegram',
      customFilter: createTextFilter({ placeholder: 'Search Telegram username' }),
      name: 'users.telegram_username',
      width: '153px',
      ...createCellLink({
        textProp: 'user.telegramUsername',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      value: 'type',
      name: 'payments.type',
      text: 'Type',
      width: '60px',
      isShown: true,
      customFilter: createTransactionsTypeFilter(),
      ...createCellNumberAndTooltips({
        icon: '$icon-message',
        type: 'text',
        typeColumn: 'comment',
      }),
    },
    {
      value: 'transactionType',
      name: 'transaction_type',
      text: 'Transaction',
      width: '60px',
      customFilter: createTransactionsFilter(),
      isShown: true,
    },
    {
      value: 'paymentMethod.type',
      name: 'paymentMethod.type',
      text: 'Payment method',
      width: '60px',
      permission: settings.permissions.viewPaymentMethods,
      customFilter: createPaymentMethodsFilter(),
      isShown: true,
    },
    {
      value: 'orderId',
      name: 'order_id',
      text: 'Order ID',
      width: '60px',
      customFilter: createTextFilter({ placeholder: 'Search Order ID' }),
      isShown: true,
    },
    {
      value: 'amount',
      name: 'payments.amount',
      text: 'Amount',
      width: '60px',
      isShown: true,
      ...createCellMoney(),
    },
    {
      name: 'payments.created_at',
      value: 'createdAt',
      text: 'Created at',
      width: '90px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
  ],
};
