import RepositoryFactory from '@/services/repository-factory';

const comboOrdersRepository = RepositoryFactory.get('comboOrders');
const state = () => ({
  repository: comboOrdersRepository,
  error: null,
  inModerationCount: 0,
});

export default state;
