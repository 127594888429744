export const defaultState = () => ({
  networks: [],
  serviceTypes: [],
  services: [],
  serviceType: {
    id: null,
    fullDescription: '',
    icon: '',
    name: '',
    description: '',
    metaDescription: '',
    metaTitle: '',
    type: 'Youtube',
    reviewsCount: null,
    reviewsRating: null,
    translations: {
      en: {
        name: '',
        title: '',
        faq: '',
        shortDescription: '',
        reviews: '',
        metaDescription: '',
        metaTitle: '',
      },
    },
  },
  networkId: null,
  serviceTypeId: null,
  isCreated: false,
  selectedLocale: 'en',
  errors: {},
  loading: false,
});

export default () => ({
  isShown: false,
  isShownNetworks: false,
  isShownServices: false,
  isShownServiceTypes: false,
  ...defaultState(),
});
