import RepositoryFactory from '@/services/repository-factory';
import websockets from '@/websockets';
import loadPendo from '@/services/functions/load-pendo';
import settings from '@/settings';

const personalRepository = RepositoryFactory.get('personal');
const hasPermission = (allPermissions, permission) => (allPermissions
  ? allPermissions.includes(permission)
  : false);

export default {
  namespaced: true,

  state: {
    router: null,
    tokenStore: null,
    isLoggingOutInProcess: false,
    profile: null,
  },

  mutations: {
    SET_TOKEN_STORE: (state, value) => { state.tokenStore = value; },
    SET_ROUTER: (state, value) => { state.router = value; },
    SET_IS_LOGGING_OUT_IN_PROCESS: (state, value) => { state.isLoggingOutInProcess = value; },
    SET_PROFILE: (state, value) => { state.profile = value; },
  },

  actions: {
    setConfig({ commit }, {
      router,
      tokenStore,
    }) {
      commit('SET_ROUTER', router);
      commit('SET_TOKEN_STORE', tokenStore);
    },
    initialRefresh({ dispatch }) {
      setInterval(() => {
        dispatch('refresh')
          .catch((error) => {
            if (error.message !== 'no_saved_token') {
              dispatch('logout');
            }
          });
      }, 20 * 60 * 1000);

      return dispatch('refresh')
        .then(() => dispatch('fetchProfile'))
        .then(() => dispatch('onProfileFetch'))
        .catch((error) => {
          if (error.message !== 'no_saved_token') {
            dispatch('logout');
          }
        });
    },
    saveToken({ state }, token) {
      state.tokenStore.saveToken(token);
    },
    clearToken({ state }) {
      state.tokenStore.clearToken();
    },
    login({ dispatch }, {
      gRecaptchaResponse,
      email,
      password,
    }) {
      return personalRepository.login({
        gRecaptchaResponse,
        email,
        password,
      })
        .then((token) => {
          dispatch('saveToken', token);

          return dispatch('fetchProfile');
        })
        .then(() => {
          dispatch('onProfileFetch');
        });
    },
    fetchProfile({ commit }) {
      return personalRepository.fetchProfile()
        .then((profile) => {
          commit('SET_PROFILE', profile);
        });
    },
    refresh({
      state, dispatch,
    }) {
      const savedToken = state.tokenStore.getSavedToken();

      if (!savedToken) {
        return Promise.reject(new Error('no_saved_token'));
      }

      return personalRepository.refresh(savedToken)
        .then((token) => {
          dispatch('saveToken', token);
        });
    },
    logout({
      state, commit, dispatch,
    }) {
      commit('SET_IS_LOGGING_OUT_IN_PROCESS', true);

      state.router.push({
        name: 'Login',
        query: { ...state.router.currentRoute.query },
      });
      commit('SET_PROFILE', null);
      dispatch('clearToken');
      commit('SET_IS_LOGGING_OUT_IN_PROCESS', false);

      dispatch('onLoggedOut');
      websockets.statusesCounters.disconnect();
    },
    onProfileFetch({ state }) {
      const event = new Event('user-data');

      event.userData = state.profile;
      window.dispatchEvent(event);

      window.userId = state.profile.id;
      websockets.statusesCounters.init();
      loadPendo(state.profile.id);
    },
    onLoggedOut() {
    },
  },

  getters: {
    isLoggedIn: (state) => !!state.profile,
    isWriteAutoRefill: (state) => state.profile.permissions.includes('write auto refill params'),
    allPermissions: (state) => state.profile?.allPermissions || [],
    hasManageWithdrawalsPermission: (_, getters) => hasPermission(
      getters.allPermissions,
      settings.permissions.manageWithdrawals,
    ),
    hasManagePackagesPermission: (_, getters) => hasPermission(
      getters.allPermissions,
      settings.permissions.managePackages,
    ),
    hasMarkAsPaidWithdrawalRequestsPermission: (_, getters) => hasPermission(
      getters.allPermissions,
      settings.permissions.markAsPaidWithdrawalRequests,
    ),
    hasApproveWithdrawalRequestsPermission: (_, getters) => hasPermission(
      getters.allPermissions,
      settings.permissions.approveWithdrawalRequests,
    ),
    checkPermission: (_, getters) => (permisson) => getters.allPermissions
      .some((existingPermission) => existingPermission === permisson),
  },
};
