import createAutoPromotionNetwork from '@/classes/createAutoPromotionNetwork';
import createAutoPromotionsRequest from '@/classes/createAutoPromotions';
import http from '../http';
import urlFormatter from '../url-formatter';
import handleErrors from '../handle-errors';

const getURL = urlFormatter({
  networksList: '/api/v1/admin/auto-promotions/networks',
  autoPromotions: '/api/v1/admin/auto-promotions',
  serviceTypes: '/api/v1/admin/auto-promotions/service-types?network_id={id}',
  servicesList: '/api/v1/admin/auto-promotions/Services',
  autoPromotion: '/api/v1/admin/auto-promotions/{id}',
  networkToggle: '/api/v1/admin/auto-promotions/{autoPromotionNetworkId}/network-toggle',
  sort: '/api/v1/admin/auto-promotions/sort',
});

export default {
  list() {
    const endpoint = getURL('networksList');

    return http.get(endpoint)
      .then((response) => response.data.data);
  },
  autoPromotionslist() {
    const endpoint = getURL('autoPromotions');

    return http.get(endpoint)
      .then((response) => createAutoPromotionsRequest(response.data.data));
  },
  addServices(obj) {
    const endpoint = getURL('autoPromotions');

    return http.post(endpoint, obj)
      .catch((error) => handleErrors(error));
  },
  networkToggle(autoPromotionNetworkId) {
    const endpoint = getURL('networkToggle', { autoPromotionNetworkId });

    return http.post(endpoint);
  },
  autoPromotion(id) {
    const endpoint = getURL('autoPromotion', { id });

    return http.get(endpoint)
      .then((response) => createAutoPromotionNetwork(response.data.data));
  },
  async delete(id) {
    const endpoint = getURL('autoPromotion', { id });

    await http.delete(endpoint);
  },
  getServiceTypesList(id) {
    const endpoint = getURL('serviceTypes', { id });

    return http.get(endpoint)
      .then((response) => response.data.data);
  },
  sort(obj) {
    const endpoint = getURL('sort');

    return http.post(endpoint, obj);
  },
  getURL,
};
