import Vue from 'vue';
import RepositoryFactory from '@/services/repository-factory';

const autoPromotionsRepository = RepositoryFactory.get('autoPromotions');
// const networksRepository = RepositoryFactory.get('networks');

export default {
  namespaced: true,
  state: {
    networks: [],
    networkId: null,
    autoPromotionNetworks: {},
    networksWithServices: [],
    activeNetworks: [],
    serviceTypes: {},
    services: [],
    isShown: false,
    selectedServices: [],
    selectedServicesList: [],
    serviceTypesList: [],
    serviceTypeId: null,
    serviceTypeIdReplace: null,
    disabledService: {
      id: null,
      name: null,
    },
  },
  mutations: {
    SET_NETWORKS: (state, value) => { state.networks = value; },
    SET_NETWORK_ID: (state, value) => { state.networkId = value; },
    SET_SERVICE_TYPE_ID: (state, value) => { state.serviceTypeId = value; },
    SET_IS_SHOWN: (state, value) => { state.isShown = value; },
    SET_AUTO_PROMOTION_NETWORK(state, {
      networkId,
      data,
    }) {
      Vue.set(state.autoPromotionNetworks, networkId, data);
    },
    SET_NETWORKS_WITH_SERVICES: (state, value) => { state.networksWithServices = value; },
    SET_SELECTED_SERVICES: (state, value) => { state.selectedServices = value; },
    SET_SERVICE_TYPE_ID_REPLACE: (state, value) => { state.serviceTypeIdReplace = value; },
    SET_DISABLE_SERVICE(state, obj) {
      state.disabledService.id = obj.id;
      state.disabledService.name = obj.name;
    },
  },
  actions: {
    addServices({ dispatch }, id) {
      dispatch('setNetworkId', id);
      dispatch('getAutoPromotion', id);
      dispatch('setModalVisibility', true);
    },
    setModalVisibility({
      commit,
      dispatch,
    }, value) {
      commit('SET_IS_SHOWN', value);

      if (!value) {
        dispatch('setServiceTypeIdReplace', null);
      }
    },
    getNetworks({ commit }) {
      return autoPromotionsRepository.list()
        .then((response) => {
          commit('SET_NETWORKS', response);
        });
    },
    getAutoPromotionsList({ commit }) {
      return autoPromotionsRepository.autoPromotionslist()
        .then((response) => {
          commit('SET_NETWORKS_WITH_SERVICES', response);
        });
    },
    getAutoPromotion({ commit }, networkId) {
      return autoPromotionsRepository.autoPromotion(networkId)
        .then((networkData) => {
          commit('SET_AUTO_PROMOTION_NETWORK', {
            networkId,
            data: networkData,
          });

          return networkData;
        });
    },
    deleteService({ dispatch }, {
      serviceId,
      networkId,
    }) {
      autoPromotionsRepository.delete(serviceId)
        .then(() => {
          dispatch('getAutoPromotion', networkId);
        });
    },
    setNetworks({ commit }, val) {
      commit('SET_NETWORKS', val);
    },
    setNetworkId({ commit }, id) {
      commit('SET_NETWORK_ID', id);
    },
    setServiceTypeId({ commit }, id) {
      commit('SET_SERVICE_TYPE_ID', id);
    },
    setSelectedServices({ commit }, value) {
      commit('SET_SELECTED_SERVICES', value);
    },
    setServiceTypeIdReplace({ commit }, id) {
      commit('SET_SERVICE_TYPE_ID_REPLACE', id);
    },
    save({
      state,
      dispatch,
    }, {
      errorNotify,
      successNotify,
    }) {
      autoPromotionsRepository.addServices({
        services: state.selectedServices.map((obj) => obj.id),
        network_id: state.networkId,
        service_type_id: state.serviceTypeId,
      })
        .then(() => {
          dispatch('getAutoPromotion', state.networkId);
          successNotify();
        })
        .catch((errors) => {
          errorNotify(errors.message);
        });
    },
    sortNetworks({
      state,
    }) {
      autoPromotionsRepository.sort({
        networks: state.networks.map((network) => network.id),
      });
    },
    networkToggle({ dispatch }, id) {
      autoPromotionsRepository.networkToggle(id)
        .then(() => {
          dispatch('getAutoPromotionsList');
        });
    },
    setDisableService({ commit }, obj) {
      commit('SET_DISABLE_SERVICE', obj);
    },
  },

  getters: {
    networks: (state) => state.networks,
    autoPromotionNetwork: (state) => (networkId) => state.autoPromotionNetworks[networkId],
    serviceTypes: (state) => state.serviceTypes,
    isShown: (state) => state.isShown,
    networksWithServices: (state) => state.networksWithServices,
    activeNetworks: (state) => state.activeNetworks,
    networkId: (state) => state.networkId,
    serviceTypeId: (state) => state.serviceTypeId,
    selectedServices: (state) => state.selectedServices,
    serviceTypesList: (state) => state.serviceTypesList,
    serviceTypeIdReplace: (state) => state.serviceTypeIdReplace,
    disabledService: (state) => state.disabledService,
  },
};
