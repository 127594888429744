import toCamelCase from '@/utils/toCamelCase';

export default function createBanners(data) {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  function transformTranslations(translations) {
    const transformed = {};

    Object.keys(translations).forEach((langKey) => {
      const innerObject = translations[langKey];
      const transformedInnerObject = {};

      Object.keys(innerObject).forEach((key) => {
        const camelKey = toCamelCase(key);

        transformedInnerObject[camelKey] = innerObject[key];
      });

      transformed[langKey] = transformedInnerObject;
    });

    return transformed;
  }

  return data.map((item) => ({
    content: item.content ? (new DOMParser().parseFromString(item.content, 'text/html')).body.innerHTML : null,
    id: item.id || null,
    informations: Array.isArray(item.informations) ? item.informations.map((info) => ({
      backgroundImage: info.background_image,
      backgroundColor: info.background_color,
      id: info.id,
      image: info.image,
      sorting: info.sorting,
      translations: transformTranslations(info.translations),
    })) : [],
    type: item.type,
    place: item.place || null,
    scrollDelay: item.scroll_delay || 0,
  }));
}
