import {
  defaultPaymentMethod, defaultState,
} from './state';

export default {
  SET_CLEAR_STATE: (state) => {
    Object.assign(state, defaultState());
    Object.assign(state.paymentMethod, defaultPaymentMethod());
  },
  SET_ERRORS: (state, val) => { state.errors = val; },
  SET_ERRORS_MESSAGE: (state, val) => { state.errorMessage = val; },
  SET_PAYMENT_METHOD_MODAL: (state, val) => { state.isShown = val; },
  SET_TYPE_MODAL: (state, val) => { state.typeModal = val; },
  SET_PAYMENT_METHOD_ID: (state, val) => { state.paymentMethodId = val; },
  SET_COLLECTIONS: (state, val) => { state.collections = val; },
  SET_SELECTED_METHOD_INNER: (state, val) => { state.selectedMethodInner = val; },
  SET_SELECTED_LOCALE_INNER: (state, val) => { state.selectedLocaleInner = val; },
  SET_SELECTED_WITHDRAW_LOCALE_INNER: (state, val) => { state.selectedWithdrawLocaleInner = val; },
  SET_LOADED_PAYMENT_METHOD: (state, val) => { state.paymentMethod = { ...val }; },
  SET_SELECTED_TRANSLATION: (state, val) => { state.selectedTranslation = { ...val }; },
  SET_SELECTED_WITHDRAW_TRANSLATION: (state, val) => { state.selectedWithdrawTranslation = { ...val }; },
  SET_PAYMENT_METHOD_TYPE: (state, val) => { state.paymentMethod.type = val; },
  SET_ALLOW_QR_CODE: (state, val) => { state.paymentMethod.allowQrCode = val; },
  SET_PAYMENT_QR_CODE: (state, val) => { state.paymentMethod.paymentQrCode = val; },
  SET_PAYMENT_POSITION: (state, val) => { state.paymentMethod.position = val; },
  SET_PAYMENT_METHOD_LIST: (state, val) => { state.paymentMethodList = val; },
  SET_IS_ALLOWED_FOR_NEW_USER: (state, val) => { state.paymentMethod.isAllowedForNewUser = val; },
  SET_ICON: (state, val) => { state.paymentMethod.icon = val; },
  SET_PREVIEW_QR_CODE: (state, val) => { state.previewQrCode = val; },
  SET_PREVIEW_ICON: (state, val) => { state.previewIcon = val; },
  SET_ICON_NAME: (state, val) => { state.iconName = val; },
  SET_QR_CODE_NAME: (state, val) => { state.qrCodeName = val; },
  SET_TRANSLATION_NAME: (state, val) => {
    state.paymentMethod.translations[state.selectedLocaleInner].name = val;
  },
  SET_TRANSLATION_ADDITIONAL_INFORMATION: (state, val) => {
    state.paymentMethod.translations[state.selectedLocaleInner].additionalInformation = val;
  },
  SET_TRANSLATION_DESCRIPTION: (state, val) => {
    state.paymentMethod.translations[state.selectedLocaleInner].description = val;
  },
  SET_TRANSLATION_WITHDRAWAL_DESCRIPTION: (state, val) => {
    state.paymentMethod.translations[state.selectedWithdrawLocaleInner].withdrawDescription = val;
  },
  SET_SECRETS: (state, val) => { state.paymentMethod.secrets = val; },
  SET_SECRET: (state, val) => {
    state.paymentMethod.secrets[val.index] = val.event;
  },
  SET_AVAILABLE_FOR_WITHDRAWAL: (state, val) => {
    state.paymentMethod.withdrawalOptions.availableForWithdrawal = val;
  },
  SET_EMPTY_PAYMENT_METHOD: (state, obj) => { state.emptyPaymentMethod = { ...obj }; },
};
