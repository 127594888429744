export const defaultState = () => ({
  translations: {
    en: {
      name: '',
    },
  },
  selectedLocale: 'en',
  selectedServices: [],
  isArchive: false,
  id: '',
  isEnabled: false,
  place: '',
  network: null,
  serviceType: null,
  position: '',
  pageServices: 1,
});

export default () => ({
  isShown: false,
  filters: {},
  error: null,
  places: [],
  selectedServices: [],
  services: [],
  selectedServiceTypes: [],
  serviceTypes: [],
  networks: [],
  positions: [],
  errors: {},
  isLoad: false,
  canMoreLoadServices: true,
  ...defaultState(),
});
