import camelToSnake from '@/utils/camelToSnake';

export default function convertTranslationsKeys(translations) {
  const converted = {};

  Object.entries(translations).forEach(([langKey, innerObject]) => {
    const {
      title,
      subTitle,
      buttonText,
    } = innerObject;

    if (!title && !subTitle && !buttonText) {
      return;
    }

    const convertedInnerObject = {};

    Object.entries(innerObject).forEach(([key, value]) => {
      const snakeKey = camelToSnake(key);

      convertedInnerObject[snakeKey] = value;
    });

    converted[langKey] = convertedInnerObject;
  });

  return converted;
}
