export default {
  isShown: (state) => state.isShown,
  isShownNetworks: (state) => state.isShownNetworks,
  isShownServices: (state) => state.isShownServices,
  isShownServiceTypes: (state) => state.isShownServiceTypes,
  isSaving: (state) => state.loading,
  serviceTypeId: (state) => state.serviceTypeId,
  networksList: (state) => state.networks,
  servicesList: (state) => state.services,
  serviceTypesList: (state) => state.serviceTypes,
  isCreated: (state) => state.isCreated,
  selectedLocale: (state) => state.selectedLocale,
  network: (state) => state.serviceType.network,
  translations: (state) => state.serviceType.translations,
  selectedIcon: (state) => state.serviceType.icon,
  faq: (state) => state.serviceType.translations[state.selectedLocale].faq,
  name: (state) => state.serviceType.translations[state.selectedLocale].name,
  title: (state) => state.serviceType.translations[state.selectedLocale].title,
  shortDescription: (state) => state.serviceType.translations[state.selectedLocale].shortDescription,
  reviews: (state) => state.serviceType.translations[state.selectedLocale].reviews,
  reviewsCount: (state) => state.serviceType.reviewsCount,
  reviewsRating: (state) => state.serviceType.reviewsRating,
  metaDescription: (state) => state.serviceType.translations[state.selectedLocale].metaDescription,
  metaTitle: (state) => state.serviceType.translations[state.selectedLocale].metaTitle,
};
