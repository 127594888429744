export default function createAutoPromotionsRequest(data) {
  return data.map((obj) => ({
    autoPromotionNetworkId: obj.auto_promotion_network_id,
    isEnabledInAutoPromotion: obj.is_enabled_in_auto_promotion,
    hasServices: obj.has_services,
    hasDisabledServices: obj.has_disabled_services,
    color: obj.color,
    icon: obj.icon || '',
    id: obj.id,
    name: obj.name,
    serviceTypes: obj.service_types?.map((serviceType) => ({
      color: serviceType.color,
      icon: serviceType.icon,
      id: serviceType.id,
      name: serviceType.name,
      services: serviceType.services.map((service) => ({
        autoRefillIsAllowed: service.auto_refill_is_allowed,
        hasRefill: service.has_refill,
        id: service.id,
        isArchived: service.is_archived,
        isDeactivated: service.is_deactivated,
        isEnabled: service.is_enabled,
        name: service.name,
        resaleMarginPercent: service.resale_margin_percent,
      })),
    })),
  }));
}
