import {
  createDateFilter, createTextFilter,
} from '../builder-definition/filters/table';
import {
  createCellPlainStatuses, createCellActions, createCellLink, createCellRuns,
} from '../builder-definition/tables';
import {
  createCellUrl, createCellNumber, createCellService,
} from '../builder-definition/tables/plain-builders';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'drip_feeds.id',
      width: '58px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'dripFeed',
        statusProp: 'status',
      }),
    },
    {
      value: 'user',
      name: 'users.email',
      text: 'Users',
      width: '153px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search User' }),
      ...createCellLink({
        textProp: 'user.email',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      value: 'user.telegramUsername',
      text: 'Telegram',
      customFilter: createTextFilter({ placeholder: 'Search Telegram username' }),
      name: 'users.telegram_username',
      width: '153px',
      ...createCellLink({
        textProp: 'user.telegramUsername',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      value: 'url',
      text: 'Url',
      width: '125px',
      sortable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search Quantity' }),
      ...createCellUrl(),
    },
    {
      data: 'total_quantity',
      value: 'totalQuantity',
      text: 'Quantity',
      width: '101px',
      customFilter: createTextFilter({ placeholder: 'Search Quantity' }),
      sortable: false,
      isShown: true,
      ...createCellNumber(),
      componentAttrs: {
        delimiter: ' ',
      },
    },
    {
      value: 'service',
      text: 'Service name',
      name: 'service.translations.name',
      width: '192px',
      sortable: false,
      customFilter: createTextFilter({ placeholder: 'Search Service name' }),
      isShown: true,
      ...createCellService(),
    },
    {
      value: 'runs',
      text: 'Runs',
      width: '72px',
      isShown: true,
      ...createCellRuns({
        currentProp: 'ordersCount',
        to: (item) => ({
          name: 'Orders',
          query: {
            type: 'drip_feed',
            id: item.id,
          },
        }),
      }),
    },
    {
      data: 'minutes_between_runs',
      value: 'minutesBetweenRuns',
      text: 'Interval',
      width: '88px',
      isShown: true,
      componentAttrs: {
        class: 'baseDataTableRowGreyText--text font-weight-medium',
      },
    },
    {
      value: 'quantity',
      text: 'Quantity',
      width: '68px',
      sortable: false,
      isShown: true,
      ...createCellNumber(),
      componentAttrs: {
        delimiter: ' ',
      },
    },
    {
      name: 'drip_feeds.created_at',
      value: 'createdAt',
      text: 'Date',
      width: '90px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      text: 'Status',
      value: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Cancel',
              value: 'cancelable',
              prop: 'cancelable',
              callBack: (item, store) => {
                store.dispatch('dripFeed/confirmedCancel', item.id);
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
