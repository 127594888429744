export default class ServiceTypeTranslation {
  constructor(data) {
    this.metaTitle = data?.meta_title || '';
    this.shortDescription = data?.short_description || '';
    this.reviews = data?.reviews || '';
    this.metaDescription = data?.meta_description || '';
    this.faq = data?.faq || '';
    this.name = data?.name || '';
    this.title = data?.title || '';
  }

  getDataForRequest() {
    return {
      meta_title: this.metaTitle,
      short_description: this.shortDescription,
      reviews: this.reviews,
      meta_description: this.metaDescription,
      faq: this.faq,
      name: this.name,
      title: this.title,
    };
  }
}
