import convertTranslationsKeys from '@/classes/createTranslationsKeys';

export default function createBannerRequestBody(formData) {
  return {
    content: formData.content,
    informations: formData.informations.length ? formData.informations?.map((info) => {
      const infoObject = {
        background_image: info.backgroundImage,
        background_color: info.backgroundColor,
        image: info.image,
        sorting: info.sorting,
        translations: convertTranslationsKeys(info.translations),
      };

      if (info?.id) {
        infoObject.id = info.id;
      }

      return infoObject;
    }) : [],
    type: formData.type,
    place: formData.place,
    scroll_delay: formData.scrollDelay || 3,
  };
}
