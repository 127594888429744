import fieldMapper from '@/services/helpers/fieldMapper';
import Translations from '@/classes/CreateTranslationByFieldMap';

const translationsFieldMap = {
  servicesMetaTitle: 'services_meta_title',
  servicesMetaDescription: 'services_meta_description',
};
const metaTagsFieldMap = {
  translations: {
    receive: {
      name: 'translations',
      handler: (translations) => new Translations(translations, translationsFieldMap),
    },
    send: {
      name: 'translations',
      handler: (translations) => translations.getDataForRequest(),
    },
  },
};

export default class MetaTags {
  constructor(metaTags, useMap = true) {
    if (useMap) {
      Object.assign(this, fieldMapper.receive(metaTags, metaTagsFieldMap));
    } else {
      Object.assign(this, metaTags);
    }
  }

  getDataForRequest() {
    return fieldMapper.send(this, metaTagsFieldMap);
  }
}
