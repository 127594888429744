import dateFormatter from '@/services/functions/date-filter';

export default function createPackageFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    createdAt: dateFormatter(data.created_at),
    ordersCount: data.package.type === 'manual' ? 1 : data.orders_count,
    status: data.status,
    url: data.url,
    package: {
      id: data.package.id,
      name: data.package.name,
      type: data.package.type ?? 'auto',
    },
    user: {
      id: data.user.id,
      email: data.user.email,
      telegramUsername: data.user.telegram_username,
    },
    actions: data.actions,
  };
}
