export default {
  setPaymentMethodModalOpen({
    dispatch,
    commit,
    state,
  }, {
    id,
    isShown,
    type,
  }) {
    dispatch('showPaymentMethodModal', isShown);
    dispatch('getPaymentMethodList');
    commit('SET_TYPE_MODAL', type);
    commit('SET_ERROR_NOTIFICATION', false);
    commit('SET_ERRORS', {});

    if (id) {
      commit('SET_PAYMENT_METHOD_ID', id);
      dispatch('fetchPaymentMethod', id);
    } else {
      commit('SET_LOADED_PAYMENT_METHOD', state.emptyPaymentMethod);
      dispatch('createTranslation');
      commit('SET_SELECTED_TRANSLATION', state.paymentMethod.translations[state.selectedLocaleInner]);
      dispatch('setSelectedMethodInner', state.collections[0]);
      dispatch('setSelectedWithdrawTranslation');
    }
  },
  showPaymentMethodModal({
    commit, dispatch,
  }, isShown) {
    commit('SET_PAYMENT_METHOD_MODAL', isShown);

    if (!isShown) {
      dispatch('resetState');
    }
  },
  async getCollections({
    rootGetters, commit, state,
  }) {
    commit('SET_EMPTY_PAYMENT_METHOD', state.paymentMethod);
    await rootGetters['paymentMethods/getRepository'].collections()
      .then((response) => {
        commit('SET_COLLECTIONS', response);
      });
  },
  async getPaymentMethodList({
    rootGetters, commit, state, dispatch,
  }) {
    await rootGetters['paymentMethods/getRepository'].list()
      .then((response) => {
        commit('SET_PAYMENT_METHOD_LIST', response);

        if (!state.paymentMethod.id) {
          dispatch('setPaymentPosition', (state.paymentMethodList?.length ?? 0) + 1);
        }
      });
  },
  setSelectedMethodInner({
    commit,
    dispatch,
  }, value) {
    commit('SET_SELECTED_METHOD_INNER', value);
    commit('SET_PAYMENT_METHOD_TYPE', value.type);
    dispatch('buildSecrets', value);
  },
  selectedPaymentMethod({
    state,
    dispatch,
  }) {
    const paymentMethod = state.collections.find((obj) => (
      obj.type === state.paymentMethod.type
    ));

    dispatch('setSelectedMethodInner', paymentMethod);
  },
  fetchPaymentMethod({
    dispatch, rootGetters, commit,
  }, id) {
    rootGetters['paymentMethods/getRepository'].show(id)
      .then((response) => {
        commit('SET_LOADED_PAYMENT_METHOD', response);
        dispatch('selectedPaymentMethod');
        dispatch('setSelectedTranslation');
        dispatch('setSelectedWithdrawTranslation');
        dispatch('setPreviewIcon', response.icon);
        dispatch('setPreviewQrCode', response.paymentQrCode);
      })
      .catch((errors) => {
        dispatch('setErrors', errors);
      });
  },
  async savePaymentMethod({
    state, dispatch, rootGetters,
  }, {
    successNotification,
    errorNotify,
  }) {
    try {
      if (state.typeModal === 'edit') {
        await rootGetters['paymentMethods/getRepository']
          .update(state.paymentMethodId, state.paymentMethod);
      } else {
        await rootGetters['paymentMethods/getRepository']
          .store(state.paymentMethod);
      }

      dispatch('paymentMethods/table/fetch', null, { root: true });
      dispatch('showPaymentMethodModal', false);

      if (successNotification) {
        successNotification();
      }
    } catch (errors) {
      dispatch('setErrors', errors);

      if (errorNotify) {
        errorNotify();
      }
    }
  },
  setSelectedLocaleInner({
    commit,
    dispatch,
  }, value) {
    commit('SET_SELECTED_LOCALE_INNER', value);
    dispatch('setSelectedTranslation');
  },
  setSelectedTranslation({
    state,
    commit,
  }) {
    commit('SET_SELECTED_TRANSLATION', state.paymentMethod.translations[state.selectedLocaleInner]);
  },
  setSelectedWithdrawTranslation({
    state,
    commit,
  }) {
    commit('SET_SELECTED_WITHDRAW_TRANSLATION', state.paymentMethod?.translations[state.selectedWithdrawLocaleInner]);
  },
  setSelectedWithdrawLocaleInner({
    commit,
    dispatch,
  }, value) {
    commit('SET_SELECTED_WITHDRAW_LOCALE_INNER', value);
    dispatch('setSelectedWithdrawTranslation');
  },
  setTranslationName({ commit }, val) {
    commit('SET_TRANSLATION_NAME', val);
  },
  setTranslationAdditionalInformation({ commit }, val) {
    commit('SET_TRANSLATION_ADDITIONAL_INFORMATION', val);
  },
  setTranslationWithdrawalDescription({ commit }, val) {
    commit('SET_TRANSLATION_WITHDRAWAL_DESCRIPTION', val);
  },
  setTranslationDescription({ commit }, val) {
    commit('SET_TRANSLATION_DESCRIPTION', val);
  },
  buildSecrets({ commit }, value) {
    commit('SET_SECRETS', value.secretFields.map((secret) => ({
      key: secret.name,
      default: secret.default,
      disabled: !!secret.default,
      options: secret.options,
    })));
  },
  updateSecret({ commit }, value) {
    commit('SET_SECRET', value);
  },
  setAvailableForWithdraw({ commit }, value) {
    commit('SET_AVAILABLE_FOR_WITHDRAWAL', value);
  },
  createTranslation({
    state,
    rootGetters,
  }) {
    rootGetters['settings/localesValuesArray'].forEach((lang) => {
      state.emptyPaymentMethod.translations[lang.value] = {
        description: '',
        name: '',
        withdrawDescription: '',
      };
    });
  },
  setAllowQrCode({ commit }, value) {
    commit('SET_ALLOW_QR_CODE', value);
  },
  setPaymentQrCode({ commit }, value) {
    commit('SET_PAYMENT_QR_CODE', value);
  },
  setPaymentPosition({ commit }, value) {
    commit('SET_PAYMENT_POSITION', value);
  },
  setIcon({ commit }, value) {
    commit('SET_ICON', value);
  },
  setPreviewIcon({ commit }, value) {
    commit('SET_PREVIEW_ICON', value);
  },
  setPreviewQrCode({ commit }, value) {
    commit('SET_PREVIEW_QR_CODE', value);
  },
  setIconName({ commit }, value) {
    commit('SET_ICON_NAME', value);
  },
  setQrCodeName({ commit }, value) {
    commit('SET_QR_CODE_NAME', value);
  },
  setIsAllowedForNewUser({ commit }, value) {
    commit('SET_IS_ALLOWED_FOR_NEW_USER', value);
  },
  setErrors({ commit }, errors) {
    commit('SET_ERRORS', errors.messages);
    commit('SET_ERRORS_MESSAGE', errors.message);
  },
  resetState({ commit }) {
    commit('SET_CLEAR_STATE');
  },
};
