import getDomainName from '@/services/functions/get-domain-name';

const host = `${window.location.protocol}//core.${getDomainName(window.location.host)}`;

export default {
  formatDate: 'YYYY-MM-DD',
  apiHost: process.env.NODE_ENV === 'production'
    ? host
    : process.env.VUE_APP_DEV_API_HOST,
  googleErrorReportingKey: process.env.VUE_APP_GOOGLE_ERROR_REPORTING_KEY,
  googleErrorReportingProjectId: process.env.VUE_APP_GOOGLE_ERROR_REPORTING_PROJECT_ID,
  googleErrorReportingServiceName: process.env.VUE_APP_GOOGLE_ERROR_REPORTING_SERVICE_NAME,
  recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITEKEY,
  wsHost: process.env.NODE_ENV === 'production'
    ? `core.${getDomainName(window.location.host)}`
    : process.env.VUE_APP_WEBSOCKET_HOST,
  permissions: {
    viewUsers: 'view users',
    viewOrders: 'view orders',
    viewFinances: 'view finances',
    viewInvoices: 'view invoices',
    manageInvoices: 'manage invoices',
    viewWithdrawals: 'view withdrawals',
    viewServices: 'view services',
    manageServicesAndProviders: 'manage services and providers',
    manageExternalPanels: 'manage external panels',
    manageExternalPanelOrders: 'manage external panel orders',
    viewReferralStatistics: 'view referral statistics',
    managePaymentMethods: 'manage payment methods',
    viewPaymentMethods: 'view payment methods',
    manageOrderWidgets: 'manage order widgets',
    manageWebhooks: 'manage webhooks',
    manageCoupons: 'manage coupons',
    manageDiscounts: 'manage discounts',
    manageFreeTrials: 'manage free trials',
    manageServiceTypes: 'manage service types',
    manageStaff: 'manage staff',
    managePackages: 'manage packages',
    manageTargets: 'manage targets',
    viewAudits: 'view audits',
    managePanelSettings: 'manage panel settings',
    manageWithdrawals: 'manage withdrawals',
    markAsPaidWithdrawalRequests: 'mark as paid withdrawal requests',
    approveWithdrawalRequests: 'approve withdrawal requests',
    manageGrades: 'manage grades',
    manageGradesForUsers: 'manage grades for user',
    viewGrades: 'view grades',
    viewChangeLogs: 'view change logs',
  },
};
