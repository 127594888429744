import Vuex from 'vuex';
import Vue from 'vue';
import subscribeMutations from './subscribers/subscribeMutations';
import auth from './modules/auth';
import settings from './modules/settings';
import instanceSettings from './modules/instance-settings';
import targetAttachments from './modules/targets';
import websockets from './modules/websockets';
import users from './modules/users';
import orders from './modules/orders';
import dripFeed from './modules/drip-feed';
import invoices from './modules/invoices';
import subscriptions from './modules/subscriptions';
import componentRelated from './modules/component-related';
import freeTrials from './modules/free-trials';
import paymentMethods from './modules/payment-methods';
import comboOrders from './modules/combo-orders';
import grades from './modules/grades';
import withdrawal from './modules/withdrawal';
import transactions from './modules/transactions';
import logs from './modules/logs';
import specialOffers from './modules/specialOffers/index';
import features from './modules/features';
import statisticsDate from './modules/statisticsDate';
import statisticsCoupon from './modules/statisticsCoupon';
import statistics from './modules/statistics';
import couponsList from './modules/coupons-list';
import discount from './modules/discount';
import networks from './modules/networksAndServiceTypes/index';
import providers from './modules/providers';
import providersServices from './modules/providers-services';
import banners from './modules/banners';
import autoPromotions from './modules/auto-promotions';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    settings,
    instanceSettings,
    targetAttachments,
    websockets,
    users,
    orders,
    dripFeed,
    invoices,
    componentRelated,
    subscriptions,
    freeTrials,
    comboOrders,
    grades,
    withdrawal,
    transactions,
    logs,
    specialOffers,
    features,
    statisticsDate,
    statisticsCoupon,
    statistics,
    couponsList,
    discount,
    paymentMethods,
    networks,
    providers,
    providersServices,
    banners,
    autoPromotions,
  },
});

store.subscribe(subscribeMutations(store));

export default store;
