import settings from '@/settings';
import {
  createDateFilter,
  createTextFilter,
  createWithdrawalMethodFilter,
} from '../builder-definition/filters/table';
import {
  createCellPlainStatuses, createCellActions, createCellLink, createCellMoney, createCellNumberAndTooltips,
} from '../builder-definition/tables';
import {
  createCellTooltips,
} from '../builder-definition/tables/plain-builders';

export default {
  headers: [
    {
      value: 'id',
      text: 'ID',
      name: 'withdrawals.id',
      width: '70px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search ID' }),
      ...createCellPlainStatuses({
        mapper: 'withdrawal',
        statusProp: 'status',
      }),
    },
    {
      value: 'user',
      name: 'users.email',
      text: 'Users',
      width: '70px',
      hideable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search User' }),
      ...createCellLink({
        textProp: 'user.email',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      value: 'user.telegramUsername',
      text: 'Telegram',
      customFilter: createTextFilter({ placeholder: 'Search Telegram username' }),
      name: 'users.telegram_username',
      width: '153px',
      ...createCellLink({
        textProp: 'user.telegramUsername',
        to: (item) => ({
          name: 'ProfileUser',
          params: {
            id: item.user.id,
          },
        }),
      }),
    },
    {
      value: 'accountNumber',
      name: 'account_number',
      text: 'Account number',
      width: '132px',
      sortable: false,
      isShown: true,
      customFilter: createTextFilter({ placeholder: 'Search number' }),
      ...createCellNumberAndTooltips({
        icon: '$icon-message',
        type: 'number',
        typeColumn: 'userComment',
      }),
    },
    {
      value: 'withdrawalMethod.name',
      name: 'payment_methods.type',
      text: 'Withdrawal method',
      width: '140px',
      sortable: false,
      isShown: true,
      permission: settings.permissions.viewPaymentMethods,
      customFilter: createWithdrawalMethodFilter(),
    },
    {
      value: 'amount',
      name: 'amount',
      text: 'Amount',
      width: '80px',
      sortable: false,
      isShown: true,
      ...createCellMoney(),
    },
    {
      name: 'withdrawals.created_at',
      value: 'createdAt',
      text: 'Date',
      width: '90px',
      isShown: true,
      customFilter: createDateFilter(),
    },
    {
      value: 'adminNote',
      name: 'admin_note',
      text: 'Admin note',
      width: '100px',
      isShown: true,
      ...createCellTooltips({
        icon: '$icon-message',
      }),
    },
    {
      value: 'transactionId',
      name: 'transaction_id',
      text: 'Transaction ID',
      width: '100px',
      isShown: true,
    },
    {
      text: 'Status',
      value: 'status',
      name: 'status',
      isHidden: true,
    },
    {
      value: 'actions',
      text: 'Actions',
      width: '60px',
      searchable: false,
      sortable: false,
      isShown: true,
      permission: settings.permissions.manageWithdrawals,
      ...createCellActions({
        actionItems: (tableElement) => {
          const defaultActions = [
            {
              text: 'Reject',
              value: 'reject',
              prop: 'reject',
              callBack: (item, store) => {
                store.dispatch('withdrawal/messageModal/openModal', {
                  id: item.id,
                  type: 'rejected',
                });
              },
            },
            {
              text: 'Approve',
              value: 'approve',
              prop: 'approve',
              callBack: (item, store) => {
                store.dispatch('withdrawal/messageModal/setApproved', {
                  id: item.id,
                });
              },
            },
            {
              text: 'Paid',
              value: 'paid',
              prop: 'paid',
              callBack: (item, store) => {
                store.dispatch('withdrawal/messageModal/openModal', {
                  id: item.id,
                  type: 'paid',
                });
              },
            },
          ];
          const excluded = [];
          const filteredActions = defaultActions.filter(({ prop }) => {
            if (!prop) return true;

            return tableElement.actions[prop]
              ? tableElement.actions[prop]
              : false;
          });
          const finalActions = filteredActions.filter(({ value }) => !excluded.includes(value));

          return finalActions;
        },
      }),
    },
  ],
};
