import { createTargetAttachmentResponse } from '@/classes/createTargetAttachment';
import createTargetableValues from '@/classes/targets/createTargetableValues';
import { createTargetTypes } from '@/classes/targets/createTargetTypes';
import createProviderFromResource from './createProvider';
import CreateServiceTypeFromResource from './createServiceType';
import createServiceTranslations from './createServiceTranslitions';

export default function createServiceFromResource(data) {
  if (!data) {
    return null;
  }

  return {
    adminNote: data.admin_note,
    id: data.id,
    name: data.name,
    translations: createServiceTranslations(data.translations),
    minQuantity: data.min_quantity,
    maxQuantity: data.max_quantity,
    quantityStep: data.quantity_step,
    minPrice: data.min_price,
    pricingPer: data.pricing_per,
    defaultPrice: data.default_price,
    autoRefillIsAllowed: data.auto_refill_is_allowed,
    autoRefillAfterHours: data.auto_refill_after_hours,
    autoRefillOnceFillPercent: data.auto_refill_once_fill_percent,
    autoRefillMinDropPercent: data.auto_refill_min_drop_percent,
    autoRefillMaxDropPercent: data.auto_refill_max_drop_percent,
    autoRefillMaxTotalRefilledPercent: data.auto_refill_max_total_refilled_percent,
    orderQuantityRatio: data.order_quantity_ratio,
    apiAccess: Boolean(data.api_access),
    restrictedAccess: Boolean(data.restricted_access),
    hasRefill: data.has_refill,
    isEnabled: data.is_enabled,
    isArchived: data.is_archived,
    isDeactivated: data.is_deactivated,
    urlIsRequired: data.url_is_required,
    realCounterCompleteIsEnabled: data.real_counter_complete_is_enabled,
    moderationIsEnabled: data.moderation_is_enabled,
    cancelByClientIsAllowed: data.cancel_by_client_is_allowed || false,
    gatherStatistic: data.gather_statistic || false,
    autoCancel: data.auto_cancel || false,
    calculatableQuantity: Boolean(data.calculable_quantity),
    serviceType: new CreateServiceTypeFromResource(data?.service_type),
    defaultQuantityForWidget: data.default_quantity_for_order_widget,
    budgetMultiplier: data.budget_multiplier,
    syncParams: data.sync_params,
    syncPrices: data.sync_prices,
    syncLabels: data.sync_labels || false,
    syncShortDescription: data.sync_short_description || false,
    syncReviews: data.sync_reviews || false,
    syncTextFields: Boolean(Number(data.sync_text_fields)),
    syncDescriptionField: data.sync_description_field || false,
    dripFeedIsAllowed: data.drip_feed_is_allowed,
    resaleMarginPercent: data.resale_margin_percent,
    promotableCheckerGroupId: data.promotable_checker_group_id,
    providers: (data.providers || []).map(createProviderFromResource),
    providersAreUnavailableForALongTime: data.providers_are_unavailable_for_a_long_time,
    lastTimeProvidersAreAvailableAt: data.last_time_providers_are_available_at,
    countries: (data.countries || []).map((country) => country.id),
    fullDescriptionLocation: data.full_description_location || 'footer',
    slug: data.slug,
    labels: data.labels,
    actions: data.actions,
    liveStreamSupportMinutes: data.live_stream_support_minutes,
    liveStreamRetentionQuantityMultiplier: data.live_stream_retention_quantity_multiplier,
    forbidsOrdersWithSameUrl: data.forbids_orders_with_same_url || false,
    buyPageUrl: data.buy_page_url,
    targetableValues: (data.targetable_values || []).map(createTargetableValues),
    targets: (data.targets || []).map(createTargetTypes),
    targetAttachments: data?.target_attachments?.length > 0
      ? data.target_attachments.map((target) => createTargetAttachmentResponse(target))
      : [],
  };
}
