export default function createPaymentMethodTranslationsRequest(translations) {
  if (!translations) {
    return {};
  }

  return Object.entries(translations)
    .reduce((acc, [key, localeTranslation]) => {
      acc[key] = {
        name: localeTranslation?.name ?? '',
        description: localeTranslation?.description ?? '',
        withdrawal_description: localeTranslation?.withdrawDescription ?? '',
        additional_information: localeTranslation?.additionalInformation ?? '',
      };

      return acc;
    }, {});
}
