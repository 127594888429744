export default function createPaymentMethodTranslationsRequest(translations) {
  if (!translations) {
    return {};
  }

  return Object.entries(translations)
    .reduce((acc, [key, localeTranslation]) => {
      acc[key] = {
        name: localeTranslation?.name ?? '',
        title: localeTranslation?.title ?? '',
        short_description: localeTranslation?.shortDescription ?? '',
        reviews: localeTranslation?.reviews ?? '',
        faq: localeTranslation?.faq ?? '',
        meta_title: localeTranslation?.metaTitle ?? '',
        meta_description: localeTranslation?.metaDescription ?? '',
      };

      return acc;
    }, {});
}
