export default {
  SET_AVAILABLE_SELLERS(state, value) {
    state.availableSellers = value.map((item) => ({
      ...item,
      selected: true,
    }));
  },
  SET_CONTACT_STEPS(state, value) {
    state.contactSteps = value;
  },
};
