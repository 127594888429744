import freezeUserDialogBuilder from '@/components/services/dialog/presets/freezeUserDialogBuilder';
import { defaultDialogSetupBuilder } from '@/components/services/dialog/dialogSetup';
import deactivateUserDialogBuilder from '@/components/services/dialog/presets/deactivateUserDialogBuilder';
import activateUserDialogBuilder
  from '@/components/services/dialog/presets/activateUserDialogBuilder';
import cancelOrderDialogBuilder
  from '@/components/services/dialog/presets/cancelOrderDialogBuilder';
import pauseOrderDialogBuilder from '@/components/services/dialog/presets/pauseOrderDialogBuilder';
import rejectOrderDialogBuilder
  from '@/components/services/dialog/presets/rejectOrderDialogBuilder';
import approveOrderDialogBuilder
  from '@/components/services/dialog/presets/approveOrderDialogBuilder';
import cancelAndReturnOrderDialogBuilder
  from '@/components/services/dialog/presets/cancelAndReturnOrderDialogBuilder';
import completeOrderDialogBuilder
  from '@/components/services/dialog/presets/completeOrderDialogBuilder';
import activateDeleteFeatureBuilder
  from '@/components/services/dialog/presets/activateDeleteFeatureBuilder';
import activateEditFeatureBuilder
  from '@/components/services/dialog/presets/activateEditFeatureBuilder';
import activateDeleteSafeFeatureBuilder
  from '@/components/services/dialog/presets/activateDeleteSafeFeatureBuilder';
import cancelDripFeedDialogBuilder from './presets/cancelDripFeedDialogBuilder';
import rejectComboOrderDialogBuilder from './presets/rejectComboOrderDialogBuilder';
import completeComboOrderDialogBuilder from './presets/completeComboOrderDialogBuilder';
import approveComboOrderDialogBuilder from './presets/approveComboOrderDialogBuilder';
import cancelSubscriptionDialogBuilder from './presets/cancelSubscriptionDialogBuilder';
import activateOrderDialogBuilder from './presets/activateOrderDialogBuilder';

const presets = {
  default: defaultDialogSetupBuilder,
  freezeUser: freezeUserDialogBuilder,
  deactivateUser: deactivateUserDialogBuilder,
  activateUser: activateUserDialogBuilder,
  cancelOrder: cancelOrderDialogBuilder,
  pauseOrder: pauseOrderDialogBuilder,
  rejectOrder: rejectOrderDialogBuilder,
  approveOrder: approveOrderDialogBuilder,
  activateOrder: activateOrderDialogBuilder,
  cancelAndReturnOrder: cancelAndReturnOrderDialogBuilder,
  completeOrder: completeOrderDialogBuilder,
  cancelDripFeed: cancelDripFeedDialogBuilder,
  rejectComboOrder: rejectComboOrderDialogBuilder,
  completeComboOrder: completeComboOrderDialogBuilder,
  approveComboOrder: approveComboOrderDialogBuilder,
  cancelSubscriprion: cancelSubscriptionDialogBuilder,
  activateDeleteFeature: activateDeleteFeatureBuilder,
  activateEditFeature: activateEditFeatureBuilder,
  activateDeleteSafeFeature: activateDeleteSafeFeatureBuilder,
};
const dialogDirector = (preset, invokeArgs) => {
  if (preset) {
    presets[preset](invokeArgs);
  }
};

export default (
  invokeArgs,
  preset = 'default',
) => {
  dialogDirector(preset, invokeArgs);
};
