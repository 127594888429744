export default function createAutoPromotionNetwork(data) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    name: data.name,
    icon: data.icon,
    color: data.color,
    serviceTypes: (data.service_types || [])?.map((item) => ({
      color: item.color,
      icon: item.icon,
      id: item.id,
      name: item.name,
      services: (item.services || [])?.map((service) => ({
        autoPromotionServiceId: service.auto_promotion_service_id,
        autoRefillIsAllowed: service.auto_refill_is_allowed,
        defaultPrice: service.default_price,
        hasRefill: service.has_refill,
        id: service.id,
        isArchived: service.is_archived,
        isDeactivated: service.is_deactivated,
        isEnabled: service.is_enabled,
        isEnabledInAutoPromotion: service.is_enabled_in_auto_promotion,
        name: service.name,
        resaleMarginPercent: service.resale_margin_percent,
        sourcePrice: service.source_price,
      })),
    })),
    sorting: data.sorting,
  };
}
