import RepositoryFactory from '@/services/repository-factory';

const BannersRepository = RepositoryFactory.get('banners');

export default {
  fetchBanners({
    commit,
    dispatch,
  }) {
    return BannersRepository.fetch()
      .then((response) => {
        commit('SET_BANNERS', response);

        dispatch('ensureAllBannersExist', response);
        dispatch('sortingBanners', 'all');
      });
  },
  updateBanner({
    state,
    dispatch,
  }, {
    successNotify,
    errorNotify,
  }) {
    dispatch('setLoading', true);
    BannersRepository.update(state.activeBanner)
      .then((response) => {
        dispatch('setBanner', response);
        dispatch('setLoading', false);
        successNotify();
      })
      .catch(() => {
        dispatch('setLoading', false);
        errorNotify();
      });
  },
  setLoading({ commit }, boolean) {
    commit('SET_LOADING', boolean);
  },
  ensureAllBannersExist({
    state,
    commit,
  }, fetchedBanners) {
    const bannersMap = fetchedBanners.reduce((map, banner) => {
      map[banner.place] = banner;

      return map;
    }, {});

    state.bannersInfo.forEach((info) => {
      const { place } = info;

      if (!bannersMap[place]) {
        commit('ADD_BANNER', place);
      }
    });
  },
  setBanner({ commit }, banner) {
    commit('SET_BANNER', banner);
  },
  setActiveBanner({ commit }, index) {
    commit('SET_ACTIVE_BANNER', index);
  },
  setActiveBannerId({ commit }, index) {
    commit('SET_ACTIVE_BANNER_ID', index);
  },
  setActivePlace({ commit }, index) {
    commit('SET_ACTIVE_PLACE', index);
  },
  addInformationToBanner({ commit }) {
    commit('UPDATE_BANNER');
  },
  deleteBannerSettings({ commit }, obj) {
    if (obj.id) {
      BannersRepository.delete({ id: obj.id })
        .then(() => {
          commit('DELETE_BANNER_SETTINGS', obj.index);
        });
    } else {
      commit('DELETE_BANNER_SETTINGS', obj.index);
    }
  },
  updateBannerInformation({
    state,
    dispatch,
    commit,
  }) {
    if (!+state.activeBanner.informations.length) {
      dispatch('addInformationToBanner');
    } else {
      commit('CHECK_TRANSLATIONS');
    }
  },
  setContent({ commit }, content) {
    commit('SET_CONTENT', content);
  },
  setScrollDelay({ commit }, scrollDelay) {
    commit('SET_SCROLL_DELAY', scrollDelay);
  },
  setBannerType({ commit }, type) {
    commit('SET_TYPE', type);
  },
  setBackgroundImage({ commit }, obj) {
    commit('SET_BACKGROUND_IMAGE', obj);
  },
  setImage({ commit }, obj) {
    commit('SET_IMAGE', obj);
  },
  setBackgroundColor({ commit }, obj) {
    commit('SET_BACKGROUND_COLOR', obj);
  },
  setTitleTextColor({ commit }, obj) {
    commit('SET_TEXT_TITLE_COLOR', obj);
  },
  setTitle({ commit }, obj) {
    commit('SET_TITLE', obj);
  },
  setSubTitleTextColor({ commit }, obj) {
    commit('SET_SUBTITLE_TEXT_COLOR', obj);
  },
  setSubTitle({ commit }, obj) {
    commit('SET_SUBTITLE', obj);
  },
  setButtonTextColor({ commit }, obj) {
    commit('SET_BUTTON_TEXT_COLOR', obj);
  },
  setButtonText({ commit }, obj) {
    commit('SET_BUTTON_TEXT', obj);
  },
  setButtonBgColor({ commit }, obj) {
    commit('SET_BUTTON_BG_COLOR', obj);
  },
  setButtonLink({ commit }, obj) {
    commit('SET_BUTTON_LINK', obj);
  },
  moveUp({ commit }, index) {
    commit('MOVE_UP', index);
  },
  moveDown({ commit }, index) {
    commit('MOVE_DOWN', index);
  },
  sortingBanners({
    commit,
    dispatch,
  }, val) {
    commit('SET_SORTED_BANNERS', val);
    dispatch('setStatus', val);
  },
  setStatus({ commit }, val) {
    commit('SET_STATUS', val);
  },
  resetInformations({ commit }) {
    commit('RESET_BANNER_INFORMATIONS');
  },
};
