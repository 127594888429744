const state = {
  loading: false,
  status: 'active',
  banners: [],
  bannersInfo: [
    {
      title: 'Autopromotion',
      place: 'autopromotion',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/autopromotion-header.png',
    },
    {
      title: 'Dashboard',
      place: 'dashboard',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/dashboard-header.png',
    },
    {
      title: 'Drip Feed',
      place: 'drip_feed',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/drip-feed-header.png',
    },
    {
      title: 'Free trails',
      place: 'free_trials',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/free-trials-header.png',
    },
    {
      title: 'Grades',
      place: 'grades',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/grades-header.png',
    },
    {
      title: 'Orders',
      place: 'orders',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/orders-header.png',
    },
    {
      title: 'Order Widget',
      place: 'order_widget',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/order-widget-header.png',
    },
    {
      title: 'Profile',
      place: 'profile',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/profile-header.png',
    },
    {
      title: 'Referral Program',
      place: 'referral_program',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/referral-program-header.png',
    },
    {
      title: 'Services and Pricing Main',
      place: 'services_pricing_main',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/services-and-pricing-main-banner.png',
    },
    {
      title: 'Services and Pricing Long',
      place: 'services_pricing_long',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/services-and-pricing-long-banner.png',
    },
    {
      title: 'Services and Pricing First Short',
      place: 'services_pricing_first_short',
      backgroundSize: '590x170 px (png, jpeg, svg)',
      imageSize: '216x170 px (png, jpeg, svg)',
      typeBanner: 'Short banner',
      bannerPlaceImg: '/banners-position/services-and-pricing-first-short-banner.png',
    },
    {
      title: 'Services and Pricing Second Short',
      place: 'services_pricing_second_short',
      backgroundSize: '590x170 px (png, jpeg, svg)',
      imageSize: '216x170 px (png, jpeg, svg)',
      typeBanner: 'Short banner',
      bannerPlaceImg: '/banners-position/services-and-pricing-second-short-banner.png',
    },
    {
      title: 'Services and Pricing Footer',
      place: 'services_pricing_footer',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/services-and-ricing-footer.png',
    },
    {
      title: 'Services Type Main',
      place: 'services_type_main',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/service-type-header.png',
    },
    {
      title: 'Services Type First Short',
      place: 'services_type_first_short',
      backgroundSize: '590x170 px (png, jpeg, svg)',
      imageSize: '216x170 px (png, jpeg, svg)',
      typeBanner: 'Short banner',
      bannerPlaceImg: '/banners-position/service-type-first-short-banner.png',
    },
    {
      title: 'Services Type Second Short',
      place: 'services_type_second_short',
      backgroundSize: '590x170 px (png, jpeg, svg)',
      imageSize: '216x170 px (png, jpeg, svg)',
      typeBanner: 'Short banner',
      bannerPlaceImg: '/banners-position/service-type-second-short-banner.png',
    },
    {
      title: 'Sidebar',
      place: 'sidebar',
      backgroundSize: '200x200 px (png, jpeg, svg)',
      imageSize: '200x75 px (png, jpeg, svg)',
      typeBanner: 'Sidebar banner',
      bannerPlaceImg: '/banners-position/sidebar.png',
    },
    {
      title: 'Create Order',
      place: 'create_order',
      backgroundSize: '2063x170 px (png, jpeg, svg)',
      imageSize: '260x170 px (png, jpeg, svg)',
      typeBanner: 'Long banner',
      bannerPlaceImg: '/banners-position/single-order-header.png',
    },
  ],
  lang: [
    'en',
    'ru',
    'es',
    'fr',
    'de',
    'hi_IN',
    'ko',
    'pt_BR',
    'tr',
    'zh_CN',
    'ja',
    'vi',
  ],
  sortedBanners: null,
  activeBanner: {},
  activeBannerId: null,
  activePlace: null,
};

export default state;
