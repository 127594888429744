import invokeDialog from '@/components/services';
import waiter from '@/store/utils/waiter';

export default {
  async complete({
    dispatch, getters, commit,
  }, comboOrderId) {
    await waiter('comboOrders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.complete({ comboOrderId });
    });
  },
  async inModerationCount({
    getters, commit,
  }) {
    const inModerationCount = await getters.getRepository.inModerationCount();

    commit('SET_IN_MODERATION_COUNT', inModerationCount);
  },
  async reject({
    dispatch, getters, commit,
  }, comboOrderId) {
    await waiter('comboOrders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.reject({ comboOrderId });
    });
  },
  async approve({
    dispatch, getters, commit,
  }, comboOrderId) {
    await waiter('comboOrders/table', {
      dispatch,
      commit,
    }, async () => {
      await getters.getRepository.approve({ comboOrderId });
    });
  },
  async confirmedReject({ dispatch }, comboOrderId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('reject', comboOrderId);
      },
      amount: 1,
    }, 'rejectComboOrder');
  },
  async confirmedComplete({ dispatch }, comboOrderId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('complete', comboOrderId);
      },
      amount: 1,
    }, 'completeComboOrder');
  },
  async confirmedApprove({ dispatch }, comboOrderId) {
    invokeDialog({
      confirmCallback: async () => {
        await dispatch('approve', comboOrderId);
      },
      amount: 1,
    }, 'approveComboOrder');
  },
};
