<template>
  <div
    v-if="instanceSettings"
    :style="globalCSSVars"
  >
    <router-view />

    <global-confirm-modal-dialog />
  </div>
</template>

<script>
import GlobalConfirmModalDialog from '@/components/GlobalConfirmModalDialog.vue';
import { mapState } from 'vuex';

export default {
  components: {
    GlobalConfirmModalDialog,
  },
  computed: {
    ...mapState('instanceSettings', ['instanceSettings']),
    globalCSSVars() {
      const theme = this.$vuetify.theme.isDark ? 'dark' : 'light';

      return {
        '--primary-color': this.$vuetify.theme.themes[theme].primary,
        '--accent-color': this.$vuetify.theme.themes[theme].accent,
      };
    },
  },
  created() {
    this.$store.dispatch('instanceSettings/fetch');
    this.$store.dispatch('comboOrders/inModerationCount');

    if (localStorage.getItem('theme') !== null) {
      this.$vuetify.theme.dark = JSON.parse(localStorage.getItem('theme'));
    }
  },
};
</script>
