import settings from './settings';

function getTranslitions(translations) {
  return Object.entries(translations).reduce((acc, [key, translation]) => {
    acc[key] = {
      name: translation.name,
      description: translation.description,
      meta_title: translation.metaTitle,
      meta_description: translation.metaDescription,
      short_description: translation.shortDescription,
      reviews: translation.reviews,
    };

    return acc;
  }, {});
}

function getPackagesContent(packageContents) {
  return packageContents.map((packageContent) => ({
    service_id: packageContent.id,
    quantity: packageContent.quantity,
    is_displayable: packageContent.isDisplayable,
  }));
}

export function createPackageStoreRequest(data) {
  const priceValue = settings.prices[data.typePrice].converting(data.price);

  return {
    network_id: data.networkId,
    translations: getTranslitions(data.translations),
    is_enabled: data.isEnabled,
    type: data.type,
    package_contents: getPackagesContent(data.packageContents),
    [settings.prices[data.typePrice].key]: priceValue,
  };
}

export function createPackageUpdateRequest(data) {
  const priceValue = settings.prices[data.typePrice].converting(data.price);

  return {
    translations: getTranslitions(data.translations),
    is_enabled: data.isEnabled,
    type: data.type,
    package_contents: getPackagesContent(data.packageContents),
    [settings.prices[data.typePrice].key]: priceValue,
  };
}
