import ServiceTypeTranslation from '@/classes/createServiceTypeTranslation';
import createNetworkFromResource from './createNetwork';

export default class CreateServiceTypeFromResource {
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.color = data?.color;
    this.description = data?.description;
    this.fullDescription = data?.full_description;
    this.metaTitle = data?.meta_title;
    this.metaDescription = data?.meta_description;
    this.panelServiceTypeId = data?.panel_service_type_id;
    this.icon = data?.icon;
    this.type = data?.type;
    this.sorting = data?.sorting;
    this.network = createNetworkFromResource(data?.network);
    this.slug = data?.slug;
    this.reviewsCount = data?.reviews_count;
    this.reviewsRating = data?.reviews_rating;
    this.translations = data?.translations
      ? Object.entries(data.translations)
        .reduce((acc, [key, value]) => {
          acc[key] = new ServiceTypeTranslation(value);

          return acc;
        }, {})
      : {};
  }

  getDataForRequest() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      full_description: this.fullDescription,
      meta_title: this.metaTitle,
      meta_description: this.metaDescription,
      panel_service_type_id: this.panelServiceTypeId,
      icon: this.icon,
      type: this.type,
      sorting: this.sorting,
      network: this.network,
      slug: this.slug,
      reviews_count: this.reviewsCount,
      translations: Object.entries(this.translations).reduce((acc, [key, value]) => {
        acc[key] = value.getDataForRequest();

        return acc;
      }, {}),
    };
  }
}
