import createProfileFromResource from '@/classes/createProfile';
import handleServerError from '@/utils/handleServerError';
import http from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  login: '/login',
  refresh: '/refresh',
  profileData: '/api/v1/profile',
});

export default {
  login({
    gRecaptchaResponse, email, password,
  }) {
    return http.post(
      getURL('login'),
      {
        'g-recaptcha-response': gRecaptchaResponse,
        email,
        password,
      },
    )
      .then((response) => response.headers.authorization.replace('Bearer ', ''))
      .catch(handleServerError((serverValidationErrors) => ({
        gRecaptchaResponse: serverValidationErrors.gRecaptchaResponse,
        email: serverValidationErrors.email,
        password: serverValidationErrors.password,
      })));
  },

  fetchProfile() {
    return http.get(getURL('profileData'))
      .then((response) => createProfileFromResource(response.data.data))
      .catch(handleServerError());
  },

  refresh(token) {
    return http.get(getURL('refresh'), {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.headers.authorization.replace('Bearer ', ''))
      .catch(handleServerError());
  },

  getURL,
};
