export default {
  banners: (state) => state.banners,
  bannersInfo: (state) => state.bannersInfo,
  sortedBanners: (state) => state.sortedBanners,
  status: (state) => state.status,
  activeBannerId: (state) => state.activeBannerId,
  activePlace: (state) => state.activePlace,
  activeBanner: (state) => state.activeBanner,
  loading: (state) => state.loading,
};
