import fieldMapper from '@/services/helpers/fieldMapper';
import PanelSocials from './CreatePanelSocials';
import PanelColors from './CreatePanelColors';
import CustomMenuItem from './CreateCustomMenuItem';
import MetaTags from './CreateMetaTags';
import PanelIntegrations from './CreatePanelIntegrations';
import PanelCurrencies from './CreatePanelCurrencies';
import PanelBanners from './CreatePanelBanners';
import ShowCreateOrderTypes from './CreateShowCreateOrderTypes';

const getCaptchaResponseValue = (val) => (typeof val === 'undefined' ? true : val);
const getFreeTrialsResponseValue = (val) => (typeof val === 'undefined' || val === null ? false : val);
const skipImageSending = (val) => (typeof val === 'string');
const settingsKeyMap = {
  panelName: 'panel_name',
  lightLogo: {
    receive: 'light_logo',
    send: {
      name: 'light_logo',
      skip: skipImageSending,
    },
  },
  darkLogo: {
    receive: 'dark_logo',
    send: {
      name: 'dark_logo',
      skip: skipImageSending,
    },
  },
  mobileLogo: 'mobile_logo',
  supportEmail: 'support_email',
  favicon: {
    receive: 'favicon',
    send: {
      name: 'favicon',
      skip: skipImageSending,
    },
  },
  invoicePrefix: 'invoice_prefix',
  emailLogo: {
    receive: 'email_logo',
    send: {
      name: 'email_logo',
      skip: skipImageSending,
    },
  },
  logoDestinationUrl: 'logo_destination_url',
  logoAlt: 'logo_alt',
  freeTrialsIsEnabled: {
    receive: {
      name: 'free_trials_is_enabled',
      handler: getFreeTrialsResponseValue,
    },
    send: 'free_trials_is_enabled',
  },
  freeTrialsIsHidden: {
    receive: {
      name: 'free_trials_is_hidden',
      handler: getFreeTrialsResponseValue,
    },
    send: 'free_trials_is_hidden',
  },
  iconLink192: {
    receive: 'icon_link_192',
    send: {
      name: 'icon_link_192',
      skip: skipImageSending,
    },
  },
  iconLink512: {
    receive: 'icon_link_512',
    send: {
      name: 'icon_link_512',
      skip: skipImageSending,
    },
  },
  termsAndConditionsUrl: 'terms_and_conditions_url',
  roundingPrecision: 'rounding_precision',
  themePreset: 'theme_preset',
  privacyPolicyUrl: 'privacy_policy_url',
  guaranteeChargePercent: 'guarantee_charge_percent',
  defaultReferralProgramPercent: 'default_referral_program_percent',
  channelStatisticsIsHidden: 'channel_statistics_is_hidden',
  enableKeywordTools: 'enable_keyword_tools',
  orderWidgetMinimumPurchaseAmount: 'minimum_purchase_amount_on_widget',
  maximalOrderCostWithoutApprovalRequired: 'maximal_order_cost_without_approval_required',
  socialValueExternalPanelId: 'social_value_external_panel_id',
  linkToVideoAboutWidget: 'link_to_video_about_widget',
  defaultMarketplaceView: 'default_marketplace_view',
  darkModeAvailable: 'dark_mode_available',
  navigation: 'navigation',
  contactInfoIsRequired: {
    receive: {
      name: 'contact_info_is_required',
      handler: Boolean,
    },
    send: 'contact_info_is_required',
  },
  extensionDownloadLink: 'extension_download_link',
  gradesEnabled: {
    receive: {
      name: 'grades_enabled',
      handler: Boolean,
    },
    send: 'grades_enabled',
  },
  gradeFaqUrl: 'grade_faq_url',
  enableTasks: {
    receive: {
      name: 'enable_tasks',
      handler: Boolean,
    },
    send: 'enable_tasks',
  },
  enableBonuses: {
    receive: {
      name: 'enable_bonuses',
      handler: Boolean,
    },
    send: 'enable_bonuses',
  },
  captchaIsEnabled: {
    receive: {
      name: 'captcha_is_enabled',
      handler: getCaptchaResponseValue,
    },
    send: 'captcha_is_enabled',
  },
  minMarginPercent: {
    receive: {
      name: 'min_margin_percent',
      handler: (val) => (Number.isNaN(val) ? 0 : Number(val)) * 100,
    },
    send: {
      name: 'min_margin_percent',
      handler: (val) => val / 100,
    },
  },
  singleOrderDesign: 'single_order_design',
};

export default class PanelSettings {
  constructor(data) {
    Object.assign(this, fieldMapper.receive(data, settingsKeyMap));
    this.socials = new PanelSocials(data.socials);
    this.colors = new PanelColors(data.colors);
    this.customMenuItems = data.custom_menu_items?.map((menuItem) => new CustomMenuItem(menuItem)) || [];
    this.metaTags = data.meta_tags ? new MetaTags(data.meta_tags) : {};
    this.integrations = new PanelIntegrations(data);
    this.currencies = new PanelCurrencies(data.currencies);
    this.banners = new PanelBanners(data.banners);
    this.showCreateOrderTypes = new ShowCreateOrderTypes(data.show_create_order_types);
  }

  getDataForRequest() {
    return {
      ...fieldMapper.send(this, settingsKeyMap),
      banners: this.banners.getDataForRequest(),
      socials: this.socials.getDataForRequest(),
      colors: this.colors.getDataForRequest(),
      currencies: this.currencies.getDataForRequest(),
      custom_menu_items: this.customMenuItems.map((menuItem) => menuItem.getDataForRequest()),
      meta_tags: this.metaTags.getDataForRequest(),
      ...this.integrations.getDataForRequest(),
      show_create_order_types: this.showCreateOrderTypes.getDataForRequest(),
    };
  }
}
