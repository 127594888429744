export default {
  SET_BANNERS: (state, value) => {
    state.banners = value.filter((obj) => obj.place !== 'tasks');
  },
  ADD_BANNER(state, place) {
    state.banners.push({
      place,
      isEnabled: false,
      content: '',
      scrollDelay: 0,
      informations: [],
    });
  },
  CHECK_TRANSLATIONS(state) {
    const emptyTranslation = {
      buttonBgColor: '',
      buttonLink: '',
      buttonText: '',
      buttonTextColor: '',
      subTitle: '',
      subTitleColor: '',
      title: '',
      titleColor: '',
    };

    state.activeBanner.informations.forEach((info) => {
      if (!info.translations) {
        Object.assign(info, { translations: {} });
      }

      state.lang.forEach((lang) => {
        if (!info.translations[lang]) {
          Object.assign(info.translations, { [lang]: { ...emptyTranslation } });
        }
      });
    });
  },
  UPDATE_BANNER(state) {
    const emptyTranslation = {
      buttonBgColor: '',
      buttonLink: '',
      buttonText: '',
      buttonTextColor: '',
      subTitle: '',
      subTitleColor: '',
      title: '',
      titleColor: '',
    };
    const translations = {};

    state.lang.forEach((lang) => {
      translations[lang] = { ...emptyTranslation };
    });

    const newInfo = {
      backgroundImage: null,
      backgroundColor: '',
      image: null,
      sorting: state.activeBanner.informations.length,
      translations,
    };

    // Добавляем новый объект в массив informations
    state.activeBanner.informations.push(newInfo);
  },
  SET_BANNER(state, banner) {
    const index = state.banners.findIndex((item) => item.place === banner.place);

    if (index !== -1) {
      Object.assign(state.banners[index], banner);
    }
  },
  SET_LOADING(state, boolean) {
    state.loading = boolean;
  },
  DELETE_BANNER_SETTINGS(state, index) {
    state.activeBanner.informations.splice(index, 1);
  },
  SET_CONTENT(state, content) {
    state.activeBanner.content = content;
  },
  SET_TYPE(state, type) {
    state.activeBanner.type = type;
  },
  SET_ACTIVE_BANNER(state, index) {
    state.activeBanner = state.sortedBanners[index];
  },
  SET_ACTIVE_BANNER_ID(state, index) {
    state.activeBannerId = state.sortedBanners[index]?.id;
  },
  SET_ACTIVE_PLACE(state, index) {
    state.activePlace = state.sortedBanners[index]?.place;
  },
  SET_SCROLL_DELAY(state, value) {
    let scrollDelay = parseInt(value, 10);

    if (scrollDelay < 1) {
      scrollDelay = 1;
    } else if (scrollDelay > 15) {
      scrollDelay = 15;
    }

    state.activeBanner.scrollDelay = scrollDelay;
  },
  SET_BACKGROUND_IMAGE(state, {
    index,
    image,
  }) {
    state.activeBanner.informations[index].backgroundImage = image;
  },
  SET_IMAGE(state, {
    index,
    image,
  }) {
    state.activeBanner.informations[index].image = image;
  },
  SET_BACKGROUND_COLOR(state, {
    index,
    color,
  }) {
    state.activeBanner.informations[index].backgroundColor = color;
  },
  SET_TEXT_TITLE_COLOR(state, {
    index,
    lang,
    color,
  }) {
    if (!state.activeBanner.informations[index].translations[lang]) {
      Object.assign(state.activeBanner.informations[index].translations, { lang: {} });
    }

    Object.assign(state.activeBanner.informations[index].translations[lang], { titleColor: color });
  },
  SET_TITLE(state, {
    index,
    lang,
    text,
  }) {
    state.activeBanner.informations[index].translations[lang].title = text;
  },
  SET_SUBTITLE_TEXT_COLOR(state, {
    index,
    lang,
    color,
  }) {
    state.activeBanner.informations[index].translations[lang].subTitleColor = color;
  },
  SET_SUBTITLE(state, {
    index,
    lang,
    text,
  }) {
    state.activeBanner.informations[index].translations[lang].subTitle = text;
  },
  SET_BUTTON_TEXT_COLOR(state, {
    index,
    lang,
    color,
  }) {
    state.activeBanner.informations[index].translations[lang].buttonTextColor = color;
  },
  SET_BUTTON_TEXT(state, {
    index,
    lang,
    text,
  }) {
    state.activeBanner.informations[index].translations[lang].buttonText = text;
  },
  SET_BUTTON_BG_COLOR(state, {
    index,
    lang,
    color,
  }) {
    state.activeBanner.informations[index].translations[lang].buttonBgColor = color;
  },
  SET_BUTTON_LINK(state, {
    index,
    lang,
    text,
  }) {
    state.activeBanner.informations[index].translations[lang].buttonLink = text;
  },
  MOVE_UP(state, index) {
    if (index > 0) {
      const [item] = state.activeBanner.informations.splice(index, 1);

      state.activeBanner.informations.splice(index - 1, 0, item);
      state.activeBanner.informations[index].sorting = index;
      state.activeBanner.informations[index - 1].sorting = index - 1;
    }
  },
  MOVE_DOWN(state, index) {
    if (index < state.activeBanner.informations.length - 1) {
      const [item] = state.activeBanner.informations.splice(index, 1);

      state.activeBanner.informations.splice(index + 1, 0, item);
      state.activeBanner.informations[index].sorting = index;
      state.activeBanner.informations[index + 1].sorting = index + 1;
    }
  },
  SET_SORTED_BANNERS(state, value) {
    if (value === 'disabled') {
      state.sortedBanners = state.banners.filter((item) => item.type === 'none');
    } else if (value === 'active') {
      state.sortedBanners = state.banners.filter((item) => item.type !== 'none');
    } else {
      state.sortedBanners = state.banners;
    }
  },
  SET_STATUS(state, val) {
    state.status = val;
  },
  RESET_BANNER_INFORMATIONS(state) {
    if (state.activeBanner.informations.length) {
      state.activeBanner.informations = state.activeBanner.informations.filter((info) => info.id);
    }
  },
};
