import { datatableFormaterStringified } from '@clickadilla/datatable-formatter';
import createUserFromResource from '@/classes/createUser';
import createDocumentsListFromResource from '@/classes/createDocumentsList';
import createValidationError from '@/services/functions/create-validation-error';
import handleErrors from '@/services/handle-errors';
import http, { cancelTokenNames } from '../http';
import urlFormatter from '../url-formatter';

const getURL = urlFormatter({
  table: '/api/v1/admin/users',
  show: '/api/v1/admin/users/{userId}',
  search: '/api/v1/admin/users/search',
  update: '/api/v1/admin/users/{userId}',
  updateRolesAndPermissions: '/api/v1/admin/users/{userId}/sync-roles-and-permissions',
  updatePaymentMethods: '/api/v1/admin/users/{userId}/sync-payment-methods',
  deactivate: '/api/v1/admin/users/{userId}/deactivate',
  freeze: '/api/v1/admin/users/{userId}/freeze',
  activate: '/api/v1/admin/users/{userId}/activate',
  documentsList: '/api/v1/admin/users/{userId}/verification-documents',
  changeGrade: '/api/v1/admin/users/{userId}/change-grade',
  currentDiscount: '/api/v1/admin/users/{userId}/user-discount',
  updateCurrentDiscount: '/api/v1/admin/users/{userId}/user-discount',
  bulkActions: '/api/v1/admin/users/bulk-actions',
  availableSellers: '/api/v1/admin/users/available-sellers',
  contactSteps: '/api/v1/admin/users/contact-steps',
  updateSellerInformation: '/api/v1/admin/users/{userId}/update-seller',
});
const setReferalProgramPercent = (value) => {
  if (value === '') {
    return null;
  }

  return (value / 100).toFixed(2);
};

export default {
  async table({
    headers, options, search,
  }) {
    const requestBody = datatableFormaterStringified({
      headers,
      options,
      search,
    });
    const endpoint = getURL('table');
    const response = await http.get(`${endpoint}?${requestBody}`, {
      applyCancelAdapter: cancelTokenNames.USERS_TABLE,
    });

    return {
      items: response.data.data.map(createUserFromResource),
      totalItemsCount: response.data.recordsFiltered,
    };
  },
  show(userId) {
    const endpoint = getURL('show', { userId });

    return http.get(endpoint)
      .then((response) => createUserFromResource(response.data.data));
  },
  deactivate({ userId }) {
    const endpoint = getURL('deactivate', { userId });

    return http.post(endpoint, userId)
      .then((response) => createUserFromResource(response.data.data))
      .catch((error) => handleErrors(error));
  },
  freeze({ userId }) {
    const endpoint = getURL('freeze', { userId });

    return http.post(endpoint, userId)
      .then((response) => createUserFromResource(response.data.data));
  },
  getCurrentDiscount({ userId }) {
    const endpoint = getURL('currentDiscount', { userId });

    return http.get(endpoint, userId)
      .then((response) => response.data.data);
  },
  updateCurrentDiscount({
    userId,
    percent,
    type,
  }) {
    const endpoint = getURL('updateCurrentDiscount', { userId });

    return http.post(endpoint, {
      percent,
      type,
    })
      .then((response) => response.data.data);
  },
  activate({ userId }) {
    const endpoint = getURL('activate', { userId });

    return http.post(endpoint, userId)
      .then((response) => createUserFromResource(response.data.data));
  },
  bulkActions(data) {
    const endpoint = getURL('bulkActions');

    return http.post(endpoint, {
      user_ids: data.userIds,
      grade_id: data.gradeId,
      seller_id: data.sellerId,
      contact_step: data.contactStep,
      status: data.status,
      referral_program_percent: data.referralProgramPercent,
    });
  },
  documentsList(userId) {
    const endpoint = getURL('documentsList', { userId });

    return http.get(endpoint, userId)
      .then((response) => createDocumentsListFromResource(response.data.data));
  },
  update(userId, user) {
    const endpoint = getURL('update', { userId });

    return http.patch(endpoint, {
      name: user.name,
      vat: user.vat,
      account_type: user.accountType,
      additional_info: user.additionalInfo,
      affiliate_code: user.affiliateCode,
      company_address: user.companyAddress,
      company_name: user.companyName,
      company_site: user.companySite,
      created_at: user.createdAt,
      deactivated_at: user.deactivatedAt,
      payment_methods: user.paymentMethods,
      messengers: user.messengers,
      referral_program_percent: setReferalProgramPercent(user.referralProgramPercent),
      referral_user_balance: user.referralUserBalance,
      task_user_balance: user.taskUserBalance,
    })
      .catch((error) => handleErrors(error, (errors) => ({
        name: errors.name,
        vat: errors.vat,
        accountType: errors.account_type,
        additionalInfo: errors.additional_info,
        affiliateCode: errors.affiliate_code,
        companyAddress: errors.company_address,
        companyName: errors.company_name,
        companySite: errors.company_site,
        createdAt: errors.created_at,
        deactivatedAt: errors.deactivated_at,
        paymentMethods: errors.payment_methods,
        referralProgramPercent: errors.referral_program_percent,
        referralUserBalance: errors.referral_user_balance,
        taskUserBalance: errors.task_user_balance,
      })));
  },
  updatePaymentMethods({
    userId,
    paymentMethodsIds,
  }) {
    const endpoint = getURL('updatePaymentMethods', { userId });

    return http.patch(endpoint, {
      payment_methods: paymentMethodsIds,
    })
      .catch(createValidationError({
        paymentMethods: 'paymentMethodsIds',
      }));
  },
  updateRolesAndPermissions({
    userId,
    roles,
    permissions,
  }) {
    const endpoint = getURL('updateRolesAndPermissions', { userId });

    return http.patch(endpoint, {
      roles,
      permissions,
    })
      .catch(createValidationError());
  },
  search({ query }) {
    const endpoint = getURL('search');

    return http.get(endpoint, {
      params: { query },
    })
      .then((response) => response.data.data);
  },
  async changeGrade({
    userId,
    gradeId,
    gradesEnabled,
    gradeDiscountsEnabled,
  }) {
    const endpoint = getURL('changeGrade', { userId });

    return http.post(endpoint, {
      grade_id: gradeId,
      grades_enabled: gradesEnabled,
      grade_discounts_enabled: gradeDiscountsEnabled,
    })
      .then((response) => createUserFromResource(response.data.data));
  },
  async availableSellers() {
    const endpoint = getURL('availableSellers');
    const { data } = await http.get(endpoint);

    if (data === undefined) {
      return [];
    }

    return data.data.map((user) => user);
  },
  async contactSteps() {
    const endpoint = getURL('contactSteps');
    const { data } = await http.get(endpoint);

    if (data === undefined) {
      return [];
    }

    return data.data;
  },
  async updateSellerInformation(user) {
    const endpoint = getURL('updateSellerInformation', { userId: user.id });

    return http.post(endpoint, {
      seller_status: user.sellerStatus,
      seller_id: user.sellerId,
      contact_step: user.contactStep,
    });
  },
  getURL,
};
