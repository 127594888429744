export default class PanelIntegrations {
  constructor(integrations) {
    this.customHtml = integrations.custom_html || '';
    this.intercomAppId = integrations.intercom_app_id;
    this.intercomSecret = integrations.intercom_secret;
    this.jivoAppId = integrations.jivo_chat_widget_id;
    this.tagManagerId = integrations.analytics?.tag_manager_id;
    this.googleAnalytics4Id = integrations.analytics?.google_analytics4_id;
    this.googleAnalytics4StreamId = integrations.analytics?.google_analytics4_stream_id;
    this.googleAnalytics4SecretKey = integrations.analytics?.google_analytics4_secret_key;
    this.yandexMetrikaId = integrations.analytics?.yandex_metrika_id;
    this.yandexMetrikaToken = integrations.analytics?.yandex_metrika_token;
    this.facebookPixelId = integrations.analytics?.facebook_pixel_id;
    this.facebookAccessToken = integrations.analytics?.facebook_access_token;
    this.crispWebsiteId = integrations.crisp_website_id;
    this.crispTokenId = integrations.crisp_token_id;
    this.crispTokenKey = integrations.crisp_token_key;
    this.telegramBotUsername = integrations.telegram_bot_username;
    this.telegramBotToken = integrations.telegram_bot_token;
  }

  getDataForRequest() {
    return {
      custom_html: this.customHtml,
      intercom_app_id: this.intercomAppId,
      intercom_secret: this.intercomSecret,
      jivo_chat_widget_id: this.jivoAppId,
      analytics: {
        tag_manager_id: this.tagManagerId,
        google_analytics4_id: this.googleAnalytics4Id,
        google_analytics4_stream_id: this.googleAnalytics4StreamId,
        google_analytics4_secret_key: this.googleAnalytics4SecretKey,
        yandex_metrika_id: this.yandexMetrikaId,
        yandex_metrika_token: this.yandexMetrikaToken,
        facebook_pixel_id: this.facebookPixelId,
        facebook_access_token: this.facebookAccessToken,
      },
      crisp_website_id: this.crispWebsiteId,
      crisp_token_id: this.crispTokenId,
      crisp_token_key: this.crispTokenKey,
      telegram_bot_username: this.telegramBotUsername,
      telegram_bot_token: this.telegramBotToken,
    };
  }
}
