import routerPermissions from './routerPermissions';

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: 'change-logs',
        name: 'ChangeLogs',
        component: () => import('@/views/Logs/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.ChangeLogs,
          },
        },
      },
      {
        path: 'transactions',
        name: 'Transactions',
        component: () => import('@/views/Transactions/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Transactions,
          },
        },
      },
      {
        path: 'withdrawal',
        name: 'Withdrawal',
        component: () => import('@/views/Withdrawal/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Withdrawal,
          },
        },
      },
      {
        path: 'finances',
        redirect: 'transactions',
      },
      {
        path: 'invoices',
        name: 'Invoices',
        component: () => import('@/views/Invoices/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Invoices,
          },
        },
      },
      {
        path: 'orders',
        name: 'Orders',
        component: () => import('@/views/Orders/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Orders,
          },
        },
      },
      {
        path: 'special-offers',
        name: 'SpecialOffers',
        component: () => import('@/views/SpecialOffers/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Discounts,
          },
        },
      },
      {
        path: 'banners',
        name: 'Banners',
        component: () => import('@/views/Banners/Index.vue'),
      },
      {
        path: 'orders/:id',
        name: 'Order',
        props: true,
        component: () => import('@/views/Order/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Orders,
          },
        },
      },
      {
        path: 'services',
        name: 'Services',
        component: () => import('@/views/Services/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Services,
          },
        },
      },
      {
        path: 'services/create',
        name: 'CreateService',
        component: () => import('@/views/EditService/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Services,
          },
        },
      },
      {
        path: 'services/:id',
        name: 'Service',
        props: true,
        component: () => import('@/views/EditService/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Services,
          },
        },
      },
      {
        path: 'subscriptions',
        name: 'Subscriptions',
        component: () => import('@/views/Subscriptions/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Orders,
          },
        },
      },
      {
        path: 'subscriptions/:id',
        name: 'Subscription',
        props: true,
        component: () => import('@/views/Subscriptions/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Orders,
          },
        },
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import('@/views/Users/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Users,
          },
        },
      },
      {
        path: 'users/:id',
        name: 'ProfileUser',
        props: true,
        component: () => import('@/views/ProfileUser/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Users,
          },
        },
      },
      {
        path: 'requests',
        name: 'Requests',
        component: () => import('@/views/Requests/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.FreeTrials,
          },
        },
      },
      {
        path: 'quests',
        name: 'Quests',
        component: () => import('@/views/Quests/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.FreeTrials,
          },
        },
      },
      {
        path: 'payment-methods',
        name: 'PaymentMethods',
        component: () => import('@/views/PaymentMethods/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.PaymentMethods,
          },
        },
      },
      {
        path: 'payment-method-bonuses/:id',
        name: 'PaymentMethodBonuses',
        props: true,
        component: () => import('@/views/PaymentMethodBonuses/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.PaymentMethods,
          },
        },
      },
      {
        path: 'order-widgets',
        name: 'OrderWidgets',
        component: () => import('@/views/OrderWidgets/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.OrderWidgets,
          },
        },
      },
      {
        path: 'providers',
        name: 'Providers',
        component: () => import('@/views/Providers/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Providers,
          },
        },
      },
      {
        path: 'referral-program',
        name: 'ReferralProgram',
        component: () => import('@/views/ReferralProgram/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.ReferralProgram,
          },
        },
      },
      {
        path: 'targets',
        name: 'Targets',
        component: () => import('@/views/Targets/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Targets,
          },
        },
      },
      {
        path: 'external-panels/:id',
        name: 'ExternalPanelPage',
        component: () => import('@/views/ExternalPanelPage/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.ExternalPanels,
          },
        },
      },
      {
        path: 'external-panel-orders',
        name: 'ExternalPanelOrders',
        component: () => import('@/views/ExternalPanelOrders/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.ExternalPanelOrders,
          },
        },
      },
      {
        path: 'coupons',
        name: 'Coupons',
        component: () => import('@/views/Coupons/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Coupons,
          },
        },
      },
      {
        path: 'discounts',
        name: 'Discounts',
        component: () => import('@/views/Discounts/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Discounts,
          },
        },
      },
      {
        path: 'loyalty-program',
        name: 'LoyaltyProgram',
        component: () => import('@/views/Grades/Index.vue'),
        meta: {
          auth: {
            roles: [routerPermissions.ManageGrades, routerPermissions.ViewGrades],
          },
        },
      },
      {
        path: 'panel-settings',
        name: 'PanelSettings',
        component: () => import('@/views/PanelSettings/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.PanelSettings,
          },
        },
      },
      {
        path: 'webhooks',
        name: 'Webhooks',
        component: () => import('@/views/Webhooks/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Webhooks,
          },
        },
      },
      {
        path: 'drip-feed',
        name: 'DripFeed',
        component: () => import('@/views/DripFeed/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.DripFeed,
          },
        },
      },
      {
        path: 'staff',
        name: 'Staff',
        component: () => import('@/views/Staff/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Staff,
          },
        },
      },
      {
        path: 'audit-log',
        name: 'AuditLog',
        component: () => import('@/views/AuditLog/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.AuditLog,
          },
        },
      },
      {
        path: 'combo-orders',
        name: 'ComboOrders',
        component: () => import('@/views/ComboOrders/Index.vue'),
        meta: {
          auth: {
            roles: routerPermissions.Orders,
          },
        },
      },
    ],
  },
  {
    path: '*',
    redirect: {
      name: 'Home',
    },
  },
];
