import createValidationError from '@/services/functions/create-validation-error';
import http from '../../http';
import urlFormatter from '../../url-formatter';
import getFormData from '../../helpers/formDataBuilder';
import PanelSettings from './CreatePanelSettings';

const getURL = urlFormatter({
  fetch: '/api/v1/admin/settings',
  update: '/api/v1/admin/settings',
  setTelegramWebhook: '/api/v1/admin/settings/set-telegram-webhook',
});

export default {
  fetch() {
    const endpoint = getURL('fetch');

    return http.get(endpoint)
      .then((response) => {
        const { data } = response;

        return new PanelSettings(data);
      });
  },
  setTelegramWebhook(token) {
    const endpoint = getURL('setTelegramWebhook');

    return http.post(endpoint, { token });
  },
  update(settings) {
    const endpoint = getURL('update');
    const formData = getFormData(settings.getDataForRequest());

    return http.post(endpoint, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .catch(createValidationError());
  },
  getURL,
};
