export default function createAssoc(array, key) {
  return array.reduce((acc, item) => {
    if (key) {
      acc[item[key]] = item;
    } else {
      acc[item] = true;
    }

    return acc;
  }, {});
}
