import createValidationError from '@/services/functions/create-validation-error';
import createBanners from '@/classes/createBanners';
import getFormData from '@/services/helpers/formDataBuilder';
import createBannerRequestBody
  from '@/services/repositories/banners-repository/createBannerRequestBody';
import createBanner from './createBanner';
import http from '../../http';
import urlFormatter from '../../url-formatter';

const getURL = urlFormatter({
  list: '/api/v1/admin/banners',
  create: '/api/v1/admin/banners',
  update: '/api/v1/admin/banners/{id}',
  delete: '/api/v1/admin/banners/{id}',
});

export default {
  fetch() {
    const endpoint = getURL('list');

    return http.get(endpoint)
      .then((response) => {
        const { data } = response;

        return createBanners(data.data);
      })
      .catch(createValidationError());
  },
  update(formData) {
    const endpoint = getURL('update', { id: formData.id });
    const data = getFormData({
      ...createBannerRequestBody(formData),
      _method: 'patch',
    });

    return http.post(endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => createBanner(response.data.data));
  },
  delete({ id }) {
    const endpoint = getURL('delete', { id });

    return http.delete(endpoint);
  },
  getURL,
};
